import React from "react"
import Nav from "../components/nav"
import Footer from "../components/footer"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = ({ data, location }) => {
  const params = new URLSearchParams(location.search);
  var url_param = location.search;
  var url_param2;

  var utm_campaign = params.get("utm_campaign");
  var utm_source = params.get("utm_source");
  var utm_medium = params.get("utm_medium");
  var utm_content = params.get("utm_content");

  if(url_param){
    url_param = url_param.replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/\?/g, '&');
  }

  if(url_param){
    url_param = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/\?/g, '&');
  }

  if(utm_campaign) {
    utm_campaign = utm_campaign.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_campaign = ""
  }
  if(utm_source) {
    utm_source = utm_source.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_source = ""
  }
  if(utm_medium) {
    utm_medium = utm_medium.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_medium = ""
  }
  if(utm_content) {
    utm_content = utm_content.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_content = ""
  }

  var url_param3 = "?utm_campaign="+utm_campaign+"&utm_source="+utm_source+"&utm_medium="+utm_medium+"&utm_content="+utm_content

  return (
    <Layout>
      <Nav header="nomenu" urlParam={url_param}/>
      <div className="no-masthead relative"></div>

      <section className="section sectionFirst">
        <div className="container py-10">
          <h1 className="text-xl md:text-3xl xl:text-4xl uppercase font-light mb-5">
            Data Protection Policy.
          </h1>
          <p className="mb-5">
            The purpose of this document (“Data Protection Policy”) is to inform you of how Trans Eurokars
            Pte Ltd and its related corporations (collectively, the "Eurokars Group") manages Personal Data
            (as defined below) which is subject to the Singapore Personal Data Protection Act (No. 26 of
            2012) (“the Act”). 
          </p>
          <p className="mb-5">
            The Eurokars Group is the authorised distributor of Mazda and MG, the authorised dealer of
            Rolls-Royce, MINI, McLaren, Pagani and BMW, and the authorised after sales service provider
            of Porsche in Singapore. Among other things, the Eurokars Group also sells pre-owned
            vehicles, provides body and paint services and offers leases of vehicles. 
          </p>
          <p className="mb-5">
            Please take a moment to read this Data Protection Policy so that you know and understand the
            purposes for which we collect, use and disclose your Personal Data.
          </p>
          <p className="mb-5">
            By interacting with us, submitting information to us, or signing up for any products and services
            offered by us, you agree and consent to the Eurokars Group, as well as its representatives
            and/or agents (collectively referred to herein as "TEK”, “the Group", "us", "we" or "our")
            collecting, using, disclosing and sharing amongst themselves your Personal Data, and
            disclosing such Personal Data to TEK's authorised service providers and relevant third parties
            in the manner set forth in this Data Protection Policy. 
          </p>
          <p className="mb-5">
            This Data Protection Policy supplements but does not supersede nor replace any other consents you
            may have previously provided to TEK in respect of your Personal Data, and your consents herein are
            additional to any rights which any member of the Eurokars Group may have at law to collect, use or
            disclose your Personal Data. 
          </p>
          <p className="mb-5">
            TEK may from time to time update this Data Protection Policy to ensure that this Data Protection Policy
            is consistent with our future developments, industry trends and/or any changes in legal or regulatory
            requirements. Please check our website www.eurokarsgroup.com regularly for the prevailing terms of
            this Data Protection Policy and updated information on the handling of your Personal Data.
          </p>
          <p className="mb-5">
            This Data Protection Policy was last updated on 1 January 2023.
          </p>
          <ol className="list-decimal list-outside">
            <li>
              Personal Data
              <ol className="list-decimal list-outside ">
                <li className="text-w-300">
                  In this Data Protection Policy, “Personal Data” refers to any data, whether true or not, about an
                  individual who can be identified (a) from that data; or (b) from that data and other information
                  to which we have or are likely to have access, including data in our records as may be updated
                  from time to time.
                </li>
                <li className="text-w-300">
                  Examples of such Personal Data you may provide to us include (depending on the nature of
                  your interaction with us) your name, NRIC, passport or other identification number, telephone
                  number(s), mailing address, email address and any other information relating to any individuals
                  which you have provided us in any forms you may have submitted to us, or via other forms of
                  interaction with you.
                </li>
              </ol>
            </li>
            <li>
              Collection of Personal Data
              <ol>
                <li>
                  <div>
                    Generally, we collect Personal Data in the following ways:
                    <p className="mt-2">
                      (a) when you submit any form, including but not limited to customer inquiry forms or other
                      forms relating to any of our products and services;
                    </p>
                    <p>
                      (b) when you enter into any agreement or provide other documentation or information in
                      respect of your interactions with us, or when you use our products and services;
                    </p>
                    <p>
                      (c) when you interact with our staff, including customer service officers, for example, via
                      telephone calls (which may be recorded), letters, fax, face-to-face meetings, social
                      media platforms and emails;
                    </p>
                    <p>
                      (d) when you interact with us via our websites or use services on our websites;
                    </p>
                    <p>
                      (e) when you request that we contact you or request that you be included in an email or
                      other mailing list;
                    </p>
                    <p>
                      (f) when you respond to our promotions, initiatives or to any request for additional PersonalData;
                    </p>
                    <p>
                      (g) when you submit an employment application or when you provide documents or
                      information including your resume and/or CVs in connection with any appointment as
                      an officer, director, representative or any other position;
                    </p>
                    <p>
                      (h) when your images are captured by us via CCTV cameras while you are within our
                      premises, or via photographs or videos taken by us or our representatives when you
                      attend our events;
                    </p>
                    <p>
                      (i) when you are contacted by, and respond to, our marketing representatives and
                      customer service officers;
                    </p>
                    <p>
                      (j) when we seek information about you and receive your Personal Data in connection with
                      your relationship with us, including for our products and services or job applications, for
                      example, from business partners, public agencies, your ex-employer, referral
                      intermediaries and the relevant authorities; and/or
                    </p>
                    <p>
                      (k) when you submit your Personal Data to us for any other reasons.
                    </p>
                  </div>
                </li>
                <li className="text-w-300">
                  When you browse our website, you generally do so anonymously but please see paragraph 5
                  below for information on cookies and other technologies which we have implemented on our
                  website. We do not, at our website, automatically collect Personal Data unless you provide such
                  information to us.
                </li>
                <li className="text-w-300">
                  If you provide us with any Personal Data relating to a third party (e.g. information of your spouse,
                  children, parents, and/or employees), by submitting such information to us, you represent to us
                  that you have obtained the consent of the third party to provide us with their Personal Data for
                  the respective purposes.
                </li>
                <li className="text-w-300">
                  You should ensure that all Personal Data submitted to us is complete, accurate, true and
                  correct. Failure on your part to do so may result in our inability to provide you with the products
                  and services you have requested, or delays in providing you with products and services you
                  have requested, or processing your applications.
                </li>
              </ol>
            </li>
            <li>
              Purposes for the Collection, Use and Disclosure of Your Personal Data
              <ol>
                <li>
                  <div>
                    Generally, TEK collects, uses and discloses your Personal Data for the following purposes:
                    <p>
                      (a) responding to, processing and handling your queries, feedback, complaints and requests;
                    </p>
                    <p>(b) verifying your identity;</p>
                    <p>
                      (c) managing and planning the administrative and business operations of TEK and complying with internal policies and procedures; 
                    </p>
                    <p>
                      (d) facilitating business asset transactions (which may extend to any mergers, acquisitions or asset sales);
                    </p>
                    <p>
                      (e) requesting feedback or participation in surveys, as well as conducting market research
                      and/or analysis for statistical, profiling or other purposes for us to design our products,
                      understand customer behaviour, preferences and market trends, and to review,
                      develop and improve the quality of our products and services;
                    </p>
                    <p>
                      (f) matching any Personal Data held which relates to you for any of the purposes listed herein;
                    </p>
                    <p>
                      (g) preventing, detecting and investigating crime and analysing and managing commercial risks;
                    </p>
                    <p>
                      (h) managing the safety and security of our premises and services (including but not limited
                      to carrying out CCTV surveillance and conducting security clearances);
                    </p>
                    <p>
                      (i) monitoring or recording phone calls and customer-facing interactions for quality
                      assurance, employee training and performance evaluation and identity verification
                      purposes;
                    </p>
                    <p>
                      (j) in connection with any claims, actions or proceedings (including but not limited to
                      drafting and reviewing documents, transaction documentation, obtaining legal advice,
                      and facilitating dispute resolution), and/or protecting and enforcing our contractual and
                      legal rights and obligations; 
                    </p>
                    <p>
                      (k) conducting any form of investigations including but not related to those relating to
                      disputes, billing, fraud, offences, prosecutions etc;
                    </p>
                    <p>
                      (l) meeting or complying with any applicable rules, laws, regulations, codes of practice or
                      guidelines issued by any legal or regulatory bodies which are binding on TEK (including
                      but not limited to responding to regulatory complaints, disclosing to regulatory bodies
                      and conducting audit checks, due diligence and investigations); and/or
                    </p>
                    <p>
                      (m) purposes which are reasonably related to the aforesaid.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    In addition, TEK collects, uses and discloses your Personal Data for the following purposes
                    depending on the nature of our relationship:
                    <p>
                      (a) If you are a purchaser or seller of automobiles or are leasing vehicles from us or
                      purchasing pre-owned vehicles from us (whether existing or prospective), or use our
                      other products or services:
                    </p>
                    <p>
                      (i) providing customer service and support (including but not limited to customer
                      relationship management, processing your orders, facilitating and providing
                      reminders of your service booking requests and appointments, providing you
                      with administrative support, administering insurance coverage, vehicle
                      warranties and special service campaigns, administering the lease of the
                      vehicle, and providing delivery and pick-up services);
                    </p>
                    <p>
                      (ii) administering and processing your requests including creating and maintaining
                      profiles of our customers in our system database for administrative purposes;
                    </p>
                    <p>
                      (iii) personalising your experience at TEK’s touchpoints and conducting market
                      research, understanding and analysing customer behaviour, location,
                      preferences and demographics in order to improve our service offerings; 
                    </p>
                    <p>
                      (iv) administering debt recovery and debt management; and/or
                    </p>
                    <p>
                      (v) purposes which are reasonably related to the aforesaid.
                    </p>
                    <p>
                      (b) If you are an employee, officer or owner of an external service provider or vendor
                      outsourced or prospected by TEK:
                    </p>
                    <p>
                      (i) assessing your organisation's suitability as an external service provider or
                      vendor for TEK;
                    </p>
                    <p>
                      (ii) managing project tenders and quotations, processing orders or managing the
                      supply of goods and services;
                    </p>
                    <p>
                      (iii) creating and maintaining profiles of our service providers and vendors in our
                      system database;
                    </p>
                    <p>
                      (iv) processing and payment of vendor invoices and bills;
                    </p>
                    <p>
                      (v) facilities management (including but not limited to issuing visitor access passes
                      and facilitating security clearance); and/or
                    </p>
                    <p>
                      (vi) purposes which are reasonably related to the aforesaid
                    </p>
                    <p>
                      (c) If you submit an application to us as a candidate for employment:
                    </p>
                    <p>(i) conducting interviews;</p>
                    <p>
                      (ii) processing your application (including but not limited to pre-recruitment checks
                      involving your qualifications and facilitating interviews);
                    </p>
                    <p>
                      (iii) obtaining references and for background screening;
                    </p>
                    <p>
                      (iv) assessing your suitability for the position applied for;
                    </p>
                    <p>
                      (v) enrolling successful candidates as our employees and facilitating human
                      resource planning and management (including but not limited to preparing
                      letters of employment, name cards and building access passes); and/or
                    </p>
                    <p>
                      (vi) purposes which are reasonably related to the aforesaid.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    Furthermore, where permitted under the Act, TEK may also collect, use and disclose your
                    Personal Data for the following “Additional Purposes”: 
                    <p>
                      (a) providing or marketing services, products and benefits to you, including promotions,
                      product upgrades, loyalty and reward programmes;
                    </p>
                    <p>
                      (b) organising roadshows and promotional events and administering contests and
                      competitions;
                    </p>
                    <p>
                      (c) matching Personal Data with other data collected for other purposes and from other
                      sources (including third parties) in connection with the provision or offering of products
                      and services;
                    </p>
                    <p>
                      (d) sending you details of products, services, special offers and rewards, either to our
                      customers generally, or which we have identified may be of interest to you;
                    </p>
                    <p>
                      (e) conducting market research, understanding and analysing customer behaviour,
                      location, preferences and demographics for us to offer you other products and services
                      as well as special offers and marketing programmes which may be relevant to your
                      preferences and profile; and/or
                    </p>
                    <p>
                      (f) purposes which are reasonably related to the aforesaid..
                    </p>
                  </div>
                </li>
                <li className="text-w-300">
                  If you have provided us with your Singapore telephone number(s) and have indicated that you
                  consent to receiving marketing or promotional information via your Singapore telephone
                  number(s), then from time to time, TEK may contact you using such Singapore telephone
                  number(s) (including via voice calls, text, fax or other means) with information about our
                 products and services.
                </li>
                <li className="text-w-300">
                  In relation to particular products and services or in your interactions with us, we may also have
                  specifically notified you of other purposes for which we collect, use or disclose your Personal
                  Data. If so, we will collect, use and disclose your Personal Data for these additional purposes
                  as well, unless we have specifically notified you otherwise.
                </li>
                <li className="text-w-300">
                  You have a choice to withdraw your consent for receiving marketing or promotional
                  materials/communication. You may contact us using the contact details found below.
                </li>
                <li className="text-w-300">
                  Please be aware that once we receive confirmation that you wish to withdraw your consent for
                  marketing or promotional materials/communication, it may take up to 30 calendar days for your
                  withdrawal to be reflected in our systems. Therefore, you may still receive marketing or
                  promotional materials/communication during this period of time. Please note that even if you
                  withdraw your consent for the receipt of marketing or promotional materials, we may still contact
                  you for other purposes in relation to the products and services that you have requested or
                  purchased from TEK.
                </li>
              </ol>
            </li>
            <li>
              Disclosure of Personal Data
              <ol>
                <li>
                  <div>
                    TEK will take reasonable steps to protect your Personal Data against unauthorised disclosure.
                    Subject to the provisions of any applicable law, your Personal Data may be disclosed, for the
                    purposes listed above (where applicable), to the following entities or parties, whether they are
                    located overseas or in Singapore: :
                    <p>(a) TEK’s related corporations and affiliates;</p>
                    <p>(b) TEK’s principals and dealers;</p>
                    <p>
                      (c) companies providing services relating to insurance to
                      TEK;
                    </p>
                    <p>
                      (d) agents, contractors, sub-contractors or third party service providers who provide
                      operational services to TEK, such as courier services, telecommunications, information
                      technology, payment, printing, billing, processing, technical services, transportation,
                      training, market research, call centre, security, or other services to TEK;
                    </p>
                    <p>
                      (e) vendors or third party service providers and our marketing and business partners in
                      connection with marketing promotions, products and services;
                    </p>
                    <p>
                      (f) any business partner, investor, assignee or transferee (actual or prospective) to
                      facilitate business asset transactions (which may extend to any merger, acquisition or
                      asset sale);
                    </p>
                    <p>
                      (g) external banks, credit card companies, other financial institutions and their respective
                      service providers;
                    </p>
                    <p>
                      (h) our professional advisers such as consultants, auditors and lawyers;
                    </p>
                    <p>
                      (i) companies providing insurance and warranty services to TEK’s automotive customers;
                    </p>
                    <p>
                      (j) relevant government ministries, regulators, statutory boards or authorities or law
                      enforcement agencies to comply with any laws, rules, guidelines and regulations or
                      schemes imposed by any governmental authority; and/or
                    </p>
                    <p>
                      (k) any other party to whom you authorise us to disclose your Personal Data to.
                    </p>
                  </div>
                </li>
              </ol>
            </li>
            <li>
              Use of Cookies, Web Beacons, and Similar Technologies on the website
              <ol>
                <li className="text-w-300">
                  When you visit or interact with our sites, services, we or our authorized service providers may
                  use cookies, web beacons, and other similar technologies for collecting and storing information
                  to help provide you with a better, faster, and safer web experience.
                </li>
                <li className="text-w-300">
                  The information collected by us or our authorised service providers may recognise a visitor as
                  a unique user and may collect information such as how a visitor arrives at our sites, what kind
                  of browser a visitor is on, what operating system a visitor is using, a visitor's IP address and a
                  visitor's click stream information and time stamp (for example, which pages they have viewed,
                  the time the pages were accessed and the time spent per web page).
                </li>
                <li>
                  <div>
                    However, we do not store any of your personal data on any of our cookies or other similar
                    technologies. The use of cookies, web beacons and similar technologies by us on our website
                    has different functions. They are either necessary for the functioning of our services, help us
                    improve our performance, or serve to provide you with extra functionalities. 
                    <p className="mt-3">
                      <span className="font-bold">Cookies</span> – Small text files (typically made up of letters and numbers) placed in the memory of
                        your browser or device when you visit a website or view a message. Cookies allow a website
                        to recognize a particular device or browser. There are several types of cookies:
                    </p>
                    <ul className="list-disc list-outside">
                      <li>
                        Session cookies expire at the end of your browser session and allow us to link your actions
                        during that particular browser session.
                      </li>
                      <li>
                        Persistent cookies are stored on your device in between browser sessions, allowing us to
                        remember your preferences or actions across multiple sites.
                      </li>
                      <li>
                        First-party cookies are set by the site you are visiting
                      </li>
                      <li>
                        Third-party cookies are set by a third party site separate from the site you are visiting.
                      </li>
                    </ul>
                    <p className="mt-3">
                      Cookies can be disabled or removed by tools that are available in most commercial
                      browsers. The preferences for each browser you use will need to be set separately and different
                      browsers offer different functionality and options.
                    </p>
                    <p>
                      <span className="font-bold">Web beacons</span> – - Small graphic images (also known as “pixel tags” or “clear GIFs”) may be
                      included on our sites and services. Web beacons typically work in conjunction with cookies to
                      profile each unique user and user behaviour.
                    </p>
                    <p>
                      <span className="font-bold">Similar technologies</span> –
                      Technologies that store information in your browser or device utilizing
                      local shared objects or local storage, such as flash cookies, HTML 5 cookies, and other web
                      application software methods. These technologies can operate across all of your browsers. We
                      do not use these technologies for storing information to target advertising to you on or off our
                      sites.
                    </p>
                    <p>
                      We may use the terms “cookies” or “similar technologies” interchangeably in our policies to
                      refer to all technologies that we may use to collect or store information in your browser or device
                      or that collect information or assist in identifying you as a unique user in the manner described
                      above.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    We offer certain site features and services that are available only through the use of these
                    technologies. You are always free to block, delete, or disable these technologies if your browser
                    so permits.
                    <p className="mt-3">
                      However, if you decline cookies or other similar technologies, you may not be able to take
                      advantage of certain site features or services tools. For more information on how you can block,
                      delete, or disable these technologies, please review your browser settings.
                    </p>
                  </div>
                </li>
              </ol>
            </li>
            <li>
              Third-Party Sites
              <ol>
                <li className="text-w-300">
                  Our website may contain links to other websites operated by third parties, including for example
                  our business partners. We are not responsible for the data protection practices of websites
                  operated by third parties that are linked to our website. We encourage you to learn about the
                  data protection practices of such third party websites. Some of these third party websites may
                  be co-branded with our logo or trademark, even though they are not operated or maintained by
                  us. Once you have left our website, you should check the applicable Data Protection Policy of
                  the third party website to determine how they will handle any information they collect from you. 
                </li>
              </ol>
            </li>
            <li>
              Contacting Us – Withdrawal of Consent, Access and Correction of your Personal Data
              <ol>
                <li>
                  <div>
                    If you:
                    <p className="mt-2">
                      (a) have any questions or feedback relating to your Personal Data or our Data Protection Policy;
                    </p>
                    <p>
                      (b) would like to withdraw your consent to any use of your Personal Data as set out in this
                      Data Protection Policy; or 
                    </p>
                    <p>
                      (c) would like to obtain access and make corrections to your Personal Data records,
                    </p>
                    <p>please contact us as follows:</p>
                    <ul className="list-disc list-outside">
                      <li>
                        Call: <a href="tel:+6563633003">6363 3003</a>
                      </li>

                      <li>
                        Email:{" "}
                        <a href="mailto:dpo@eurokars.com.sg">
                          dpo@eurokars.com.sg
                        </a>
                      </li>

                      <li>Write to our Data Protection Officer at:</li>
                    </ul>
                    <p>
                      <span className="font-bold block mt-2">
                        Data Protection Officer
                      </span>
                      Eurokars Centre, 12 Sungei Kadut, Singapore 729648
                    </p>
                  </div>
                </li>
                <li className="text-w-300">
                  Please note that if your Personal Data has been provided to us by a third party (e.g. a referrer
                  or your company), you should contact that organisation or individual to make such queries,
                  complaints, and access and correction requests to TEK on your behalf. 
                </li>
                <li className="text-w-300">
                  If you withdraw your consent to any or all use of your Personal Data, depending on the nature
                  of your request, TEK may not be in a position to continue to provide its products and services
                  to you, or administer any contractual relationship in place, which in turn may also result in the
                  termination of any agreements with TEK, and your being in breach of your contractual
                  obligations or undertakings. TEK’s legal rights and remedies in such event are expressly
                  reserved.
                </li>
              </ol>
            </li>
            <li>
              Governing Law
              <ol>
                <li className="text-w-300">
                  This Data Protection Policy shall be governed in all respects by the laws of Singapore.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </section>
      <Footer urlParam={url_param} />
    </Layout>
  )
}

export default PrivacyPage

const seoDetails = {
  title: "Eurokars Auto | Privacy Policy",
  description: "Data Protection Policy.",
  keywords: "Private Policy",
  image: "og-eka-privacy-policy.jpg",
}
export const Head = ({ data }) => (
  <>
    <title>{seoDetails.title}</title>
    <meta property="og:type" content="website" />
    <meta
      name="description"
      property="description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="og:description"
      property="og:description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="keywords"
      property="keywords"
      content={seoDetails.keywords}
    ></meta>
    <meta
      name="og:url"
      property="og:url"
      content={`${process.env.GATSBY_BASE_URL}`}
    ></meta>
    <meta
      name="og:image"
      property="og:image"
      content={`${process.env.GATSBY_BASE_URL}/img/${seoDetails.image}`}
    ></meta>

    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-782099393"
    ></script>

  </>
)
